<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input>
        </div>
        <div>
          <el-button type="text" class="fs-16" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增</i>
          </el-button>
          <el-button type="text" class="ml-40 fc-666 fs-16" @click="$emit('getRecord')">
            <i class="icon iconfont icon-daochu mr-10 fs-18"></i>签到记录
          </el-button>
          <!-- <el-button type="text" class="ml-40 fc-666 fs-16" @click="exportExcel">
            <i class="icon iconfont icon-daochu mr-10 fs-18"></i>导出
          </el-button> -->
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:propImg="e">
          <img :src="e.row.propImg" alt="" class="smallImg" v-if="e.row.propImg" />
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="10vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="签到天数" prop="signDay" v-if="'signDay' in ruleForm"
          :rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.signDay"></el-input>
        </el-form-item>
        <el-form-item label="道具类型" prop="propType" v-if="'propType' in ruleForm"
          :rules="{required: true, message: '请至少选择一个道具卡类型', trigger: 'change'}">
          <el-select v-model="ruleForm.propType" placeholder="请选择">
            <el-option v-for="item in propTypes"  :label="item.key" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="propImg" v-if="'propImg' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
            iconName="el-icon-upload" iconSize="26" v-model="ruleForm.propImg"></upload-file>
        </el-form-item>
        <el-form-item label="道具名称" prop="rewardName" v-if="'rewardName' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.rewardName"></el-input>
        </el-form-item>
        <el-form-item label="道具数量" prop="propNum" v-if="'propNum' in ruleForm"
          :rules="{required: true, message: '道具数量不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.propNum"></el-input>
        </el-form-item>
        <el-form-item label="有效天数" prop="validDay" v-if="'validDay' in ruleForm"
          :rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.validDay"></el-input>
        </el-form-item>
        <el-form-item label="优惠券面额" prop="money" v-if="'money' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '优惠券面额不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.money"></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponsType" v-if="'couponsType' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
          <el-select v-model="ruleForm.couponsType" placeholder="请选择">
            <el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用门槛" prop="doorSill" v-if="'doorSill' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '使用门槛不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.doorSill"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'signInData',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'propTypes': state => state.dictList ? state.dictList.prop_type : [],  //道具类型
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      tabLoading: false,
      butLoading: false,
      columns: [
        { label: '签到天数', prop: 'signDay', align: 'center', width: '80' },
        { columnType: 'custom', label: '道具图片', prop: 'propImg', align: 'left', width: '80' },
        { label: '道具名称', prop: 'rewardName', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '道具类型', prop: 'propType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.propTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '道具数量', prop: 'propNum', align: 'left', width: '80' },
        { label: '有效天数', prop: 'validDay', align: 'left', width: '80' },
        { label: '优惠券面额', prop: 'money', align: 'left', width: '100' },
        {
          columnType: 'fun', label: '优惠券类型', prop: 'couponsType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.couponsTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '优惠券使用门槛', prop: 'doorSill', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList, },
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogTitle: '新增',
      dialogFormVisible: false,
      ruleForm: {
        signDay: '', //签到天数
        rewardName: '', //道具名称
        propType: '', //道具类型
        propImg: '',//道具图片
        propNum: '', //道具数量
        validDay: '', //有效天数
        money: '', //优惠券面额
        doorSill: '', //使用门槛
        couponsType: '', //优惠券类型
      },
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () { },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/signIn/findSignInRewardList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //新增列表
    addList () {
      this.dialogTitle = "新增"
      let tableData = this.tableData
      let signDay = 0
      if (tableData.length > 0) {
        signDay = tableData[tableData.length - 1].signDay + 1
      }
      this.ruleForm = {
        signDay: signDay,
        rewardName: '',
        propType: '',
        propImg: '',
        propNum: '',
        validDay: '',
        money: '',
        doorSill: '',
        couponsType: '',
      }
      this.dialogFormVisible = true
    },
    //编辑列表
    editList (row) {
      this.dialogTitle = "编辑"
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    //删除列表
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/signIn/removeSignInReward', { id: row.id }).then(({ data: result }) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableData()
        })
      }).catch(_ => { })
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ""
          if (this.dialogTitle == '新增') {
            apiUrl = "/signIn/addSignInReward"
          } else if (this.dialogTitle == '编辑') {
            apiUrl = "/signIn/editSignInReward"
          }
          let ruleForm = this.ruleForm
          if (ruleForm.propType != 4) {
            ruleForm.money = ''
            ruleForm.doorSill = ''
            ruleForm.couponsType = ''
          }
          this.$http.post(apiUrl, ruleForm).then(({ data: result }) => {
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.butLoading = false
            this.getTableData()
            this.dialogFormVisible = false
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.$data.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>